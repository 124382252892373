"use client"

import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { ChromePicker } from "react-color"

import {
  DMPopover,
  DMPopoverContent,
  DMPopoverTrigger,
  DMTokenPreviewSwatch,
  rgbToHex8,
} from "@supernovaio/dm"
import { Input, InputProps } from "@supernovaio/dm/src/components/primitives"

import { expandToHexColor } from "expand-to-hex-color"

export type DMColorInputProps = Pick<
  InputProps,
  "name" | "id" | "hasError" | "preventEnterSubmit" | "dataTestId"
> & {
  value: string | null
  onChange: (value: string | null) => void
  isDisabled?: InputProps["disabled"]
  isReadOnly?: InputProps["readOnly"]
  isRequired?: InputProps["required"]
  hasPlaceholder?: boolean
  placeholder?: string
  hasAlphaSlider?: boolean
}

const DMColorInput = React.forwardRef<HTMLInputElement, DMColorInputProps>(
  (
    {
      value,
      isDisabled,
      isReadOnly,
      isRequired,
      onChange,
      hasPlaceholder,
      placeholder = "e.g. #000000",
      hasAlphaSlider = true,
      dataTestId = "color_input",
      ...restProps
    },
    ref
  ) => {
    const [rawValue, setRawValue] = useState<string | null>(value)
    const rawValueRef = useRef<string | null>(rawValue)
    const onChangeRef = useRef(onChange)

    const updateRawValue = useCallback((newValue: string | null) => {
      setRawValue(newValue)
      rawValueRef.current = newValue
    }, [])

    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)

    useEffect(() => {
      updateRawValue(value)
    }, [updateRawValue, value])

    const memoizedOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        updateRawValue(event.currentTarget.value)
      },
      [updateRawValue]
    )

    const memoizedUpdateValue = useCallback(
      (
        event:
          | React.KeyboardEvent<HTMLInputElement>
          | React.FocusEvent<HTMLInputElement>
      ) => {
        // Convert raw value to a color and propagate it to the parent if needed

        let newValue: string | null = null
        const rawValue = event.currentTarget.value

        if (rawValue) {
          // Try to convert `rawValue` to a color

          const hexColor =
            rawValue.toLowerCase() === "auto"
              ? null
              : expandToHexColor(rawValue, "uppercase")

          if (hexColor) {
            // Entered `rawValue` can be converted
            newValue = hexColor
          }
        }

        if (!newValue) {
          // Conversion failed.
          // Non-required inputs allow clearing the value, otherwise rollback to the previous value.
          if (
            !isRequired &&
            (rawValue?.trim() === "" || rawValue?.toLowerCase() === "auto")
          ) {
            newValue = null
          } else {
            newValue = value
          }
        }

        updateRawValue(newValue)
        onChange(newValue)
      },
      [updateRawValue, value, isRequired, onChange]
    )

    const memoizedOnKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
          memoizedUpdateValue(event)
          event.currentTarget.blur()
        }
      },
      [memoizedUpdateValue]
    )

    // on cleanup, we need to check if the raw value is a valid color and if not, convert it to a color and propagate it to the parent
    useEffect(() => {
      return () => {
        if (rawValueRef.current) {
          const hexColor =
            rawValueRef.current.toLowerCase() === "auto"
              ? null
              : expandToHexColor(rawValueRef.current, "uppercase")

          if (
            hexColor &&
            hexColor.toUpperCase() !== rawValueRef.current.toUpperCase()
          ) {
            onChangeRef.current(hexColor)
          }
        }
        if (rawValueRef.current) {
          const rawColor =
            rawValueRef.current.toLowerCase() === "auto"
              ? null
              : rawValueRef.current.toUpperCase()

          if (
            rawColor &&
            rawColor.toUpperCase() !== rawValueRef.current.toUpperCase()
          ) {
            onChangeRef.current(rawColor)
          }
        }
      }
    }, [])

    return (
      <Input
        {...restProps}
        ref={ref}
        selectOnFocus
        disabled={isDisabled}
        placeholder={hasPlaceholder ? placeholder : undefined}
        readOnly={isReadOnly}
        required={isRequired}
        dataTestId={dataTestId}
        size="medium"
        startSlot={
          <DMPopover
            modal
            open={isColorPickerOpen}
            onOpenChange={setIsColorPickerOpen}
          >
            <DMPopoverTrigger>
              <div
                onClick={() => {
                  setIsColorPickerOpen(true)
                }}
                style={{ cursor: "pointer" }}
              >
                <DMTokenPreviewSwatch
                  resolvedStyle={{
                    background:
                      expandToHexColor(rawValue || "", "uppercase") ||
                      undefined,
                  }}
                  token={
                    rawValue
                      ? {
                          tokenType: "color",
                          displayValue:
                            expandToHexColor(rawValue || "", "uppercase") || "",
                        }
                      : null
                  }
                />
              </div>
            </DMPopoverTrigger>
            <DMPopoverContent
              onPointerDown={(event) => event.stopPropagation()}
              size="small"
            >
              <ChromePicker
                className="!w-[180px]"
                color={rawValue ?? "#000000"}
                disableAlpha={!hasAlphaSlider}
                onChange={({ rgb }) => {
                  updateRawValue(rgbToHex8(rgb).toUpperCase())
                }}
                onChangeComplete={({ rgb }) => {
                  onChange(rgbToHex8(rgb).toUpperCase())
                }}
              />
            </DMPopoverContent>
          </DMPopover>
        }
        type="text"
        value={rawValue || ""}
        onBlur={memoizedUpdateValue}
        onChange={memoizedOnChange}
        onKeyDown={memoizedOnKeyDown}
      />
    )
  }
)

DMColorInput.displayName = "DMColorInput"

export { DMColorInput }
