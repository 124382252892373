import {
  ShadowTokenMetadata,
  TypographyTokenMetadata,
} from "../DMTokenPreview.types"

import Color from "colorjs.io"

export function isTypographyTokenMetadata(
  object: any
): object is TypographyTokenMetadata {
  return true
}

export function isShadowTokenMetadata(
  object: any
): object is ShadowTokenMetadata {
  return true
}

// Checks if font size larger than 12px for tiny swatch to have better control over the preview
export function meetsMinimumFontSizeRequirements(
  value: number,
  unit: string,
  minimumAllowedValue = 12
) {
  if (unit === "rem") {
    value = minimumAllowedValue * 16
  }

  return value < minimumAllowedValue
}

function isValidHexColor(str: string) {
  const regex = /^#([0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?([0-9A-Fa-f]{2})?)$/
  return regex.test(str)
}

export function contrastColor(color?: string): "dark" | "light" {
  if (!color || !isValidHexColor(color)) {
    return "dark"
  }

  // check opacity of the color if 8HEX
  if (color.length === 9) {
    const opacity = parseInt(color.slice(7, 9), 16) / 255
    if (opacity < 0.45) {
      return "dark"
    }
  }

  // TODO: check if this is somehow fixable in new version of colorjs.io
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore basd on types there are not args but based on docs there are
  const newColor = new Color(color)

  const onWhite = Math.abs(newColor.contrast("white", "APCA"))
  const onBlack = Math.abs(newColor.contrast("black", "APCA"))

  // Return black for bright colors, white for dark colors
  return onWhite > onBlack ? "light" : "dark"
}
